import React, { useState } from 'react';
import { relocate } from '../helpers/general';
// import { Builder, BuilderComponent } from '@builder.io/react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import FormInputField from '../components/atoms/FormInputField/FormInputField';
import Button from '../components/atoms/Button/Button';

import * as styles from './404.module.scss';
import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import Icon from '../components/atoms/Icon/Icon';
import { Link, graphql, useStaticQuery } from 'gatsby';

const PageNotFound = () => {
  // if (Builder.isPreviewing || Builder.isEditing) {
  //   return <BuilderComponent model="page" />;
  // }
  const data = useStaticQuery(graphql`
    query GetBanner {
      allBuilderModels {
        onePageNotFoundBanner {
          data {
            banners
          }
        }
      }
    }
  `);

  const [popularSearches] = useState([
    'Dry Food',
    'Advocate',
    'Hill’s',
    'Apoquel'
  ]);

  const [search, setSearch] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    relocate(`/search?q=${search}`);
  };

  return (
    <Layout disablePaddingBottom>
      <Seo title="Page Not Found" />
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>Sorry,</h1>
          <p className="search-text">
            We've dug around for the page you tried to reach <br /> but can't
            seem to find it.
          </p>
          <Flex className="home-link" as={Link} to="/">
            <Icon symbol={'chevRight'} />
            <p>BACK TO HOME</p>
          </Flex>
          <form onSubmit={e => handleSubmit(e)}>
            <div className={styles.searchContainer}>
              <FormInputField
                id={'name'}
                value={search}
                handleChange={(_, e) => setSearch(e)}
                type={'text'}
                placeholder={'Search'}
              />
              <IconButton type="submit" colorScheme="teal">
                <Icon symbol={'search'} />
              </IconButton>
              <Button type={'submit'} level={'primary'}>
                search
              </Button>
            </div>
          </form>
          <p className="popular-text">Popular searches:</p>
          <Flex align={'center'} justify={'center'} gap={2} wrap={'wrap'}>
            {popularSearches.map((search, index) => (
              <Button
                key={index}
                onClick={() => setSearch(search)}
                className={'popular-searches'}
                type={'button'}
                level={'ghost'}
              >
                {search}
              </Button>
            ))}
          </Flex>

          <section>
            <h3>Shop by Pet</h3>
            <Flex  gap={[4,4,7]} justify={['flex-start', 'flex-start', 'center']} overflowX={'auto'}>
              {data?.allBuilderModels?.onePageNotFoundBanner?.data?.banners?.map(
                (banner, index) => (
                  <Box
                    key={index}
                    as={Link}
                    to={banner.link}
                    flexShrink={0}
                  >
                    <Image
                      m={0}
                      height={117}
                      w={117}
                      src={banner.image}
                      alt={banner.text}
                    />
                    <Text mt={2}>{banner.text}</Text>
                  </Box>
                )
              )}
            </Flex>
          </section>
        </div>
      </Container>
    </Layout>
  );
};

export default PageNotFound;
